import { render, staticRenderFns } from "./error.vue?vue&type=template&id=68fc1da3"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NFTWidget: require('/home/runner/work/liker-land/liker-land/src/components/NFTWidget/index.vue').default,Label: require('/home/runner/work/liker-land/liker-land/src/components/Label.vue').default,ButtonV2: require('/home/runner/work/liker-land/liker-land/src/components/ButtonV2.vue').default})
