import { render, staticRenderFns } from "./Snackbar.vue?vue&type=template&id=7fd965a0"
import script from "./Snackbar.vue?vue&type=script&lang=ts"
export * from "./Snackbar.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconError: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Error.vue').default,IconClose: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Close.vue').default,Label: require('/home/runner/work/liker-land/liker-land/src/components/Label.vue').default})
