
import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';

// eslint-disable-next-line import/prefer-default-export
export enum DialogPreset {
  Basic = 'preset',
  Custom = 'custom',
}

@Component
export default class Dialog extends Vue {
  // Preset of the dialog.
  @Prop({ default: DialogPreset.Basic })
  readonly preset!: DialogPreset;

  // Vertically center the dialog.
  @Prop({ default: true })
  readonly isVerticallyCenter!: boolean;

  // Panel container class.
  @Prop({ default: '' })
  readonly panelContainerClass!: string;

  // Panel class.
  @Prop({ default: '' })
  readonly panelClass!: string;

  // Panel style.
  @Prop({ default: '' })
  readonly panelStyle!: string;

  // Header text.
  @Prop({ default: '' })
  readonly headerText!: string;

  // Content text.
  @Prop({ default: '' })
  readonly text!: string;

  // Panel tag.
  @Prop({ default: 'div' })
  readonly panelComponent!: string;

  // Set to true to disable close by backdrop click
  @Prop({ default: false })
  readonly isDisabledBackdropClick!: boolean;

  // Set to false to hide the close button
  @Prop({ default: true })
  readonly hasCloseButton!: boolean;

  @Prop({ default: 'z-[50]' })
  readonly zIndexClass!: string;

  @Prop({ default: () => [] })
  readonly scrollableWrapperClasses!: any;

  // Show/Hide dialog.
  @ModelSync('open', 'toggle', { type: Boolean, default: false })
  isOpen!: boolean;

  get isBasic() {
    return this.preset === DialogPreset.Basic;
  }

  get isCustom() {
    return this.preset === DialogPreset.Custom;
  }

  get scrollableClasses() {
    return [
      'flex',
      'justify-center',
      'px-[8px]',
      'py-[80px]',
      'bg-medium-gray',
      'bg-opacity-25',
      'backdrop-filter',
      'backdrop-blur-lg',
      'min-h-screen',
      this.isVerticallyCenter ? 'items-center' : 'pt-[100px]',
      ...this.scrollableWrapperClasses,
    ];
  }

  close() {
    this.$emit('close');
    this.isOpen = false;
  }

  handleBackdropClick() {
    if (!this.isDisabledBackdropClick) {
      this.close();
    }
  }
}
