var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'p-[4px]',
    'transition',
    'duration-100',
    'cursor-pointer',
    { 'text-like-green': _vm.value === _vm.selectedValue },
    { 'font-[600]': _vm.value === _vm.selectedValue },
    'hover:text-medium-gray',
    'active:text-like-green',
  ],on:{"click":function($event){return _vm.handleSelectItem(_vm.value)}}},[_c('Label',{class:['py-[16px]', _vm.labelClass],attrs:{"align":_vm.labelAlign},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._t("label-prepend")]},proxy:true},{key:"append",fn:function(){return [_vm._t("label-append")]},proxy:true}],null,true)},[_vm._v(" "),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.label))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }