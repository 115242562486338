
import { mapActions, mapGetters } from 'vuex';

import walletMixin from '~/mixins/wallet';
import { ellipsis, formatNumber } from '~/util/ui';
import { logTrackerEvent } from '~/util/EventLogger';

import Logo from '~/assets/icons/logo.svg?inline';
import GlobeIcon from '~/assets/icons/globe.svg?inline';

export default {
  name: 'SiteHeader',
  filters: {
    ellipsis,
    formatNumber,
  },
  components: {
    Logo,
    GlobeIcon,
  },
  mixins: [walletMixin],
  props: {
    isPlain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowMobileMenu: false,
    };
  },
  computed: {
    ...mapGetters([
      'getHomeRoute',
      'getUserId',
      'getNotificationCount',
      'shoppingCartBookProductList',
    ]),
    currentLocale() {
      return this.$i18n.locale;
    },
    availableLocales() {
      return this.$i18n.locales.map(locale => ({
        value: locale.code,
        name: this.$t(`Locale.${locale.code}`),
      }));
    },
    mainMenuItems() {
      const options = [
        { value: 'portfolio', name: this.$t('main_menu_my_portfolio') },
        { value: 'notifications', name: this.$t('main_menu_notification') },
        { value: 'setting', name: this.$t('main_menu_settings') },
        { value: 'signOut', name: this.$t('main_menu_sign_out') },
      ];
      return options;
    },
    formattedNotificationCount() {
      const { getNotificationCount: count } = this;
      return `${Math.min(count, 99)}${count > 99 ? '+' : ''}`;
    },
  },
  async mounted() {
    await this.restoreSession();
  },
  methods: {
    ...mapActions(['updatePreferences', 'userLogout']),
    handleClickGoStore() {
      logTrackerEvent(this, 'site_header', 'site_header_click_store', '', 1);
    },
    handleSelectLocale(value) {
      this.updatePreferences({ locale: value });
    },
    handleSelectMenuItem(value) {
      switch (value) {
        case 'notifications':
          logTrackerEvent(
            this,
            'site_menu',
            'site_menu_click_notifications',
            '',
            1
          );
          this.$router.push(this.localeLocation({ name: 'notifications' }));
          break;

        case 'portfolio':
          logTrackerEvent(
            this,
            'site_menu',
            'site_menu_click_book_shelf',
            '',
            1
          );
          this.$router.push(
            this.localeLocation({
              name: 'bookshelf',
              params: { id: this.getAddress || this.loginAddress },
              query: { tab: 'collected' },
            })
          );
          break;

        case 'setting':
          logTrackerEvent(this, 'site_menu', 'site_menu_click_settings', '', 1);
          this.$router.push(this.localeLocation({ name: 'settings' }));
          break;

        case 'signOut':
          logTrackerEvent(this, 'site_menu', 'site_menu_click_signout', '', 1);
          this.disconnectWallet();
          if (this.getUserId) {
            this.userLogout();
          }
          break;

        default:
          break;
      }
    },
    handleOpenSider() {
      this.isShowMobileMenu = true;

      if (this.getAddress) {
        logTrackerEvent(
          this,
          'site_menu',
          'site_menu_click_sider_menu',
          this.getAddress,
          1
        );
      } else {
        logTrackerEvent(
          this,
          'site_menu',
          'site_menu_click_sider_login',
          '',
          1
        );
      }
    },
  },
};
