import { render, staticRenderFns } from "./LinkV2.vue?vue&type=template&id=d57b34aa"
import script from "./LinkV2.vue?vue&type=script&lang=ts"
export * from "./LinkV2.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconNorthEast: require('/home/runner/work/liker-land/liker-land/src/components/Icon/NorthEast.vue').default})
