import { render, staticRenderFns } from "./MenuIcon.vue?vue&type=template&id=6bb7bd23"
import script from "./MenuIcon.vue?vue&type=script&lang=ts"
export * from "./MenuIcon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconPerson: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Person.vue').default,IconPrice: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Price.vue').default,IconArticle: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Article.vue').default,IconLikeEvent: require('/home/runner/work/liker-land/liker-land/src/components/Icon/LikeEvent.vue').default,IconSetting: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Setting.vue').default,IconLogOut: require('/home/runner/work/liker-land/liker-land/src/components/Icon/logOut.vue').default,IconMint: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Mint.vue').default,IconCreativeWork: require('/home/runner/work/liker-land/liker-land/src/components/Icon/CreativeWork.vue').default,IconBell: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Bell.vue').default})
