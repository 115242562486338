
export default {
  name: 'EventModalCollectMethodButton',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    discountInfo: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.type);
    },
  },
};
