
import Logo from '~/assets/icons/logo.svg?inline';
import { logTrackerEvent } from '~/util/EventLogger';

import crispMixin from '~/mixins/crisp';
import { APP_LIKE_CO_URL_BASE } from '~/constant';

import Dialog from './Dialog';

export default {
  name: 'Footer',
  components: {
    Dialog,
    Logo,
  },
  mixins: [crispMixin],
  data() {
    return {
      isOpenAboutTeam: false,
    };
  },
  computed: {
    mintNftURL() {
      return `${APP_LIKE_CO_URL_BASE}/nft`;
    },
  },
  mounted() {
    window.CustomSubstackWidget = {
      substackUrl: 'likerland.substack.com',
      placeholder: 'example@gmail.com',
      buttonText: this.$t('footer_subscribe_newsletter_button'),
      theme: 'custom',
      colors: {
        primary: '#AAF1E7',
        input: '#00000000',
        email: '#AAF1E7',
        text: '#28646E',
      },
    };
  },
  methods: {
    handleClickHelp() {
      logTrackerEvent(this, 'footer', 'footer_click_help', '', 1);
      if (this.$crisp) {
        this.showCrisp();
        this.$crisp.push(['do', 'chat:open']);
      }
    },
    handleClickButton(buttonName) {
      logTrackerEvent(this, 'footer', `footer_click_${buttonName}`, '', 1);
    },
  },
};
