
import pageNotFound from '~/assets/images/error/404.svg';
import severError from '~/assets/images/error/500.svg';
import crispMixin from '~/mixins/crisp';

export default {
  mixins: [crispMixin],
  layout: 'empty',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    errorInfo() {
      const errorObj = {};
      switch (String(this.error.statusCode)[0]) {
        case '5':
          errorObj.image = severError;
          errorObj.title = this.$t('error_page_not_found_title_500');
          errorObj.description = this.$t(
            'error_page_not_found_description_500'
          );
          break;

        case '4':
        default:
          errorObj.image = pageNotFound;
          errorObj.title = this.$t('error_page_not_found_title_404');
          errorObj.description = this.$t(
            'error_page_not_found_description_404'
          );
          break;
      }
      return errorObj;
    },
  },
  methods: {
    handleClickReport() {
      if (this.$crisp) {
        this.showCrisp();
        this.$crisp.push(['do', 'chat:open']);
      } else {
        window.open(
          'https://discord.com/channels/763001015712350231/814761730349596712'
        );
      }
    },
  },
};
