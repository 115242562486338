
import Avatar from './Identity.avatar';

export default {
  name: 'Identity',
  components: {
    Avatar,
  },
  props: {
    avatarSize: {
      type: Number,
      default: 40,
    },
    avatarUrl: {
      type: String,
      default: '',
    },
    isLazyLoaded: {
      type: Boolean,
      default: false,
    },
    isAvatarDisabled: {
      type: Boolean,
      default: false,
    },
    isAvatarOutlined: {
      type: Boolean,
      default: false,
    },
    isAvatarOutlineExtruded: {
      type: Boolean,
      default: true,
    },
    likerId: {
      type: String,
      default: '',
    },
    likerIdClass: {
      type: String,
      default: '',
    },
    displayName: {
      type: String,
      default: '',
    },
    displayNameClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClickAvatar(e) {
      this.$emit('click-avatar', e);
    },
  },
};
