
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Menu extends Vue {
  @Prop(String)
  readonly value: string | undefined;

  @Prop(String)
  readonly label: string | undefined;

  @Prop([Object, String])
  readonly labelClass: object | string | undefined;

  @Prop(String)
  readonly selectedValue: string | undefined;

  @Prop({ default: 'center' })
  readonly labelAlign!: string;

  handleSelectItem(value: string) {
    this.$emit('select', value);
  }
}
