export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en","file":"en.json"},{"code":"zh-Hant","iso":"zh-Hant","file":"zh-Hant.json"}],
  defaultLocale: "zh-Hant",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "~/locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://liker.land",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en","file":"en.json"},{"code":"zh-Hant","iso":"zh-Hant","file":"zh-Hant.json"}],
  localeCodes: ["en","zh-Hant"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "e",
  24: "n",
  25: ".",
  26: "j",
  27: "s",
  28: "o",
  29: "n",
  30: "\"",
  31: ",",
  32: "\"",
  33: "z",
  34: "h",
  35: "-",
  36: "H",
  37: "a",
  38: "n",
  39: "t",
  40: ".",
  41: "j",
  42: "s",
  43: "o",
  44: "n",
  45: "\"",
  46: ":",
  47: "\"",
  48: ".",
  49: ".",
  50: "/",
  51: "l",
  52: "o",
  53: "c",
  54: "a",
  55: "l",
  56: "e",
  57: "s",
  58: "/",
  59: "z",
  60: "h",
  61: "-",
  62: "H",
  63: "a",
  64: "n",
  65: "t",
  66: ".",
  67: "j",
  68: "s",
  69: "o",
  70: "n",
  71: "\"",
  72: "}",
}

export const localeMessages = {
  'en.json': () => import('../../locales/en.json' /* webpackChunkName: "lang-en.json" */),
  'zh-Hant.json': () => import('../../locales/zh-Hant.json' /* webpackChunkName: "lang-zh-Hant.json" */),
}
