import { render, staticRenderFns } from "./index.vue?vue&type=template&id=65a69046"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconPrice: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Price.vue').default,NFTPageOwning: require('/home/runner/work/liker-land/liker-land/src/components/NFTPage/Owning.vue').default,Label: require('/home/runner/work/liker-land/liker-land/src/components/Label.vue').default,NFTMessageIdentity: require('/home/runner/work/liker-land/liker-land/src/components/NFTMessage/Identity.vue').default,ProgressIndicator: require('/home/runner/work/liker-land/liker-land/src/components/ProgressIndicator.vue').default,IconCheck: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Check.vue').default,IconEye: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Eye.vue').default,ButtonV2: require('/home/runner/work/liker-land/liker-land/src/components/ButtonV2.vue').default,IconShare: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Share.vue').default,Separator: require('/home/runner/work/liker-land/liker-land/src/components/Separator.vue').default,IconMessage: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Message.vue').default,IconEmail: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Email.vue').default,EventModalCollectMethodButton: require('/home/runner/work/liker-land/liker-land/src/components/EventModal/Collect/MethodButton.vue').default,TxModal: require('/home/runner/work/liker-land/liker-land/src/components/TxModal.vue').default})
