import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9db21da0"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NFTWidgetContentPreview: require('/home/runner/work/liker-land/liker-land/src/components/NFTWidget/ContentPreview.vue').default,NFTWidgetCollectActionBar: require('/home/runner/work/liker-land/liker-land/src/components/NFTWidget/CollectActionBar.vue').default,NFTWidgetBaseCard: require('/home/runner/work/liker-land/liker-land/src/components/NFTWidget/BaseCard.vue').default})
