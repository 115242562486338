var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",class:_vm.rootClasses,attrs:{"to":_vm.to || null,"target":_vm.$attrs.href && !_vm.$attrs.target ? '_blank' : _vm.$attrs.target}},'component',_vm.rootProps,false),_vm.$listeners),[(_vm.isThemeGlow)?_c('div',{class:[
      'absolute inset-0',
      { [_vm.backgroundClassForPreset]: !_vm.isDisabled },
      'rounded-full',
      'blur-[4px]',
      'translate-x-[4px]',
      'translate-y-[4px]',
    ]}):_vm._e(),_vm._v(" "),_c('Label',{class:_vm.labelClass,attrs:{"text":_vm.text,"content-class":_vm.contentClass,"prepend-class":_vm.prependClass,"append-class":_vm.appendClass,"preset":_vm.textPreset,"tag":_vm.labelTag,"align":_vm.align},scopedSlots:_vm._u([(_vm.shouldShowPrepend)?{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true}:null,(_vm.shouldShowAppend)?{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}:null],null,true)},[_vm._v(" "),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }