
import { mapGetters } from 'vuex';

import LikerLandLogo from '~/assets/icons/logo.svg?inline';
import LikerLogo from '~/assets/images/liker-logo.svg?inline';

export default {
  name: 'DialogLayout',
  components: {
    LikerLandLogo,
    LikerLogo,
  },
  computed: {
    ...mapGetters(['getHomeRoute']),
  },
};
