import { render, staticRenderFns } from "./CollectActionBar.vue?vue&type=template&id=5b75bb29"
import script from "./CollectActionBar.vue?vue&type=script&lang=js"
export * from "./CollectActionBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NFTWidgetIconInsertCoin: require('/home/runner/work/liker-land/liker-land/src/components/NFTWidget/Icon/InsertCoin.vue').default})
