
import { formatNumberWithLIKE } from '~/util/ui';

export default {
  filters: {
    formatNumberWithLIKE,
  },
  props: {
    collectButtonTitle: {
      type: String,
      default: 'Collect Now',
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onClickCollectButton(e) {
      this.$emit('collect', e);
    },
  },
};
