
import DropdownMenu from 'v-dropdown-menu/vue2';

export default {
  components: {
    DropdownMenu,
  },
  props: {
    direction: {
      type: String,
      default: 'right',
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    closeOnChildClick: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggle() {
      // TODO: Deprecated. Remove this method in the future.
    },
    handleBodyClick() {
      if (this.closeOnChildClick && this.$refs.dropdown) {
        this.$refs.dropdown.hide();
      }
    },
  },
};
