
import { mapGetters } from 'vuex';

import { logTrackerEvent } from '~/util/EventLogger';

import alertMixin from '~/mixins/alert';
import inAppMixin from '~/mixins/in-app';
import nftMixin from '~/mixins/nft';

export default {
  mixins: [alertMixin, inAppMixin, nftMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    headerText: {
      type: String,
      default: undefined,
    },
    completeText: {
      type: String,
      default: undefined,
    },
    // Preset of modal, option: collect and transfer
    preset: {
      type: String,
      default: undefined,
    },
    processingTitle: {
      type: String,
      default: '',
    },
    completeTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return { isShowQuitConfirm: false };
  },
  computed: {
    ...mapGetters([
      'uiIsChainUpgrading',
      'uiTxNFTStatus',
      'uiTxErrorMessage',
      'uiCollectOwnedCount',
      'walletMethodType',
      'getAddress',
    ]),
    dialogProps() {
      const props = {
        panelContainerClass: 'fixed max-w-[520px] w-full',
        panelClass:
          'shadow-lg bg-white w-full p-[48px] phone:p-[16px] rounded-[24px] max-h-[80vh]',
        open: this.isOpen,
        hasCloseButton: this.hasCloseButton || !!this.uiTxErrorMessage,
        headerText: this.headerText,
        preset: 'basic',
      };
      return props;
    },
    formattedErrorMessage() {
      switch (this.uiTxErrorMessage) {
        case 'ALREADY_MINTED':
          return '';

        case 'INSUFFICIENT_BALANCE':
          return this.$t('snackbar_error_insufficient');

        case 'NFT_IS_ALREADY_BOUGHT':
          return this.$t('snackbar_error_nft_is_already_bought');

        case 'INSUFFICIENT_GAS_FEE':
          return this.$t('snackbar_error_out_of_gas');

        case 'SEQ_MISMATCH':
          return this.$t('snackbar_error_seq_mismatch');

        default:
          return this.uiTxErrorMessage;
      }
    },
    formattedStatusTitle() {
      switch (this.uiTxNFTStatus) {
        case 'sign':
          switch (this.walletMethodType) {
            case 'keplr':
              return this.$t('tx_modal_status_sign_title_keplr');

            case 'keplr-mobile':
              return this.$t('tx_modal_status_sign_title_keplrMobile');

            case 'cosmostation':
              return this.$t('tx_modal_status_sign_title_cosmostation');

            case 'likerland-app':
              return this.$t('tx_modal_status_sign_title_likerId');

            default:
              return undefined;
          }
        case 'processing':
        case 'processing_non_blocking':
          return (
            this.processingTitle || this.$t('tx_modal_status_processing_title')
          );
        case 'completed':
          return (
            this.completeTitle || this.$t('tx_modal_status_complete_title')
          );
        default:
          return undefined;
      }
    },
    formattedStatusText() {
      switch (this.uiTxNFTStatus) {
        case 'sign':
          if (
            this.walletMethodType === 'keplr' ||
            this.walletMethodType === 'cosmostation'
          ) {
            return this.$t('tx_modal_status_sign_text');
          }
          return undefined;

        case 'processing':
        case 'processing_non_blocking':
          return this.$t('tx_modal_status_processing_text');

        case 'completed':
          return this.completeText;
        default:
          return undefined;
      }
    },
    attentionText() {
      switch (this.walletMethodType) {
        case 'keplr-mobile':
          return this.$t('attention_keplrMobile_openApp');

        case 'likerland-app':
          if (this.isInInAppBrowser) return undefined;
          return this.$t('attention_likerland_openApp');

        case 'cosmostation':
        default:
          return undefined;
      }
    },
    buttonText() {
      switch (this.uiTxNFTStatus) {
        case 'sign':
          return this.$t('tx_modal_button_cancel');

        case 'insufficient':
        case 'failed':
          if (this.nftTxErrorIsAlreadyCollected) {
            return undefined;
          }
          return this.$t('tx_modal_button_retry');

        case 'completed':
          if (this.preset === 'collect' && this.$slots.button) {
            return undefined;
          }
          return this.$t('tx_modal_button_ok');

        case 'processing':
        case 'processing_non_blocking':
        default:
          return undefined;
      }
    },
    getButtonState() {
      return {
        preset: this.uiTxNFTStatus === 'sign' ? 'tertiary' : 'outline',
        text: this.buttonText,
      };
    },
  },
  methods: {
    onClick() {
      switch (this.uiTxNFTStatus) {
        case 'sign':
          logTrackerEvent(
            this,
            'NFT',
            'tx_modal_quit_button_clicked',
            this.classId,
            1
          );
          this.isShowQuitConfirm = true;
          break;
        case 'insufficient':
        case 'failed':
          logTrackerEvent(
            this,
            'NFT',
            'tx_modal_retry_button_clicked',
            this.classId,
            1
          );
          this.$emit('close');
          window.location.reload();
          break;
        case 'completed':
        default:
          logTrackerEvent(
            this,
            'NFT',
            'tx_modal_close_button_clicked',
            this.classId,
            1
          );
          this.$emit('close');
          this.isShowQuitConfirm = false;
          break;
      }
    },
    handleContinue() {
      logTrackerEvent(this, 'NFT', 'ClickContinue', this.classId, 1);
      this.isShowQuitConfirm = false;
    },
    handleCancel() {
      logTrackerEvent(this, 'NFT', 'Cancel', this.classId, 1);
      this.$emit('close');
      this.isShowQuitConfirm = false;
    },
  },
};
