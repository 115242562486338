
import { mapGetters } from 'vuex';

import Logo from '~/assets/icons/logo.svg?inline';

export default {
  name: 'SiteMenuForMobile',
  components: {
    Logo,
  },
  computed: {
    ...mapGetters(['getHomeRoute']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
