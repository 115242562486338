
import { mapGetters } from 'vuex';

export default {
  props: {
    collectedCount: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters(['uiTxNFTStatus']),
    formattedCollectedCount() {
      return this.collectedCount?.toString() || '-';
    },
  },
};
