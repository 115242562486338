import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4c758ae0"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AlertBanner: require('/home/runner/work/liker-land/liker-land/src/components/AlertBanner.vue').default,SiteHeader: require('/home/runner/work/liker-land/liker-land/src/components/SiteHeader.vue').default,Footer: require('/home/runner/work/liker-land/liker-land/src/components/Footer.vue').default,LinkV2: require('/home/runner/work/liker-land/liker-land/src/components/LinkV2.vue').default,Snackbar: require('/home/runner/work/liker-land/liker-land/src/components/Snackbar.vue').default,EventModalCollect: require('/home/runner/work/liker-land/liker-land/src/components/EventModal/Collect/index.vue').default})
