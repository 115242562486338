
import IconCreditCard from '~/assets/icons/credit-card.svg?inline';
import IconLike from '~/assets/icons/like-icon.svg?inline';

export default {
  name: 'EventModalCollectMethodIcon',
  components: {
    IconLike,
    IconCreditCard,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
