
import { mapGetters } from 'vuex';

import { logTrackerEvent } from '~/util/EventLogger';

import LocalMallIcon from '~/assets/icons/local-mall.svg?inline';

export default {
  name: 'ShoppingCartSiteButton',
  components: {
    LocalMallIcon,
  },
  props: {
    isPlain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getAddress', 'shoppingCartBookProductList']),
  },
  methods: {
    handleClickCartButton() {
      logTrackerEvent(
        this,
        'site_button',
        'shopping_cart_click_site_button',
        this.getAddress,
        1
      );
    },
  },
};
