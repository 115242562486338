
import { getLikeCoResizedImageUrl } from '../../util/ui';
import { DEFAULT_AVATAR } from '../../constant';

export default {
  name: 'IdentityAvatar',
  props: {
    size: {
      type: Number,
      default: 40,
    },
    url: {
      type: String,
      default: DEFAULT_AVATAR,
    },
    displayName: {
      type: String,
      default: '',
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isOutlineExtruded: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLazyLoaded: {
      type: Boolean,
      default: false,
    },
    shouldResizeImage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rootClass() {
      return [
        'avatar',
        {
          avatar__disabled: this.isDisabled,
          avatar__intruded: !this.isOutlineExtruded,
        },
      ];
    },
    imageSrc() {
      const imageSrc = this.url || DEFAULT_AVATAR;
      if (!this.shouldResizeImage) {
        return imageSrc;
      }
      return getLikeCoResizedImageUrl(imageSrc, this.size);
    },
    imageStyle() {
      const borderWidth = `${Math.floor(this.size * 0.05)}px`;
      const width = `${this.size}px`;
      return {
        width,
        height: width,
        borderWidth,
      };
    },
    loadingType() {
      return this.isLazyLoaded ? 'lazy' : undefined;
    },
  },
};
