
export default {
  props: {
    primaryButtonText: {
      type: String,
      default: '',
    },
    primaryButtonHref: {
      type: String,
      default: '',
    },
    primaryButtonTo: {
      type: Object,
      default: undefined,
    },
    secondaryButtonText: {
      type: String,
      default: '',
    },
    secondaryButtonHref: {
      type: String,
      default: '',
    },
    secondaryButtonTo: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    handleClickCloseButton(event) {
      this.isOpen = false;
      this.$emit('close', event);
    },
  },
};
