export const AlertBanner = () => import('../../components/AlertBanner.vue' /* webpackChunkName: "components/alert-banner" */).then(c => wrapFunctional(c.default || c))
export const AuthRequiredView = () => import('../../components/AuthRequiredView.vue' /* webpackChunkName: "components/auth-required-view" */).then(c => wrapFunctional(c.default || c))
export const BalanceCard = () => import('../../components/BalanceCard.vue' /* webpackChunkName: "components/balance-card" */).then(c => wrapFunctional(c.default || c))
export const ButtonV2 = () => import('../../components/ButtonV2.vue' /* webpackChunkName: "components/button-v2" */).then(c => wrapFunctional(c.default || c))
export const CardV2 = () => import('../../components/CardV2.vue' /* webpackChunkName: "components/card-v2" */).then(c => wrapFunctional(c.default || c))
export const CheckBox = () => import('../../components/CheckBox.vue' /* webpackChunkName: "components/check-box" */).then(c => wrapFunctional(c.default || c))
export const CivicLikerWeb3Notice = () => import('../../components/CivicLikerWeb3Notice.vue' /* webpackChunkName: "components/civic-liker-web3-notice" */).then(c => wrapFunctional(c.default || c))
export const CollapsibleCard = () => import('../../components/CollapsibleCard.vue' /* webpackChunkName: "components/collapsible-card" */).then(c => wrapFunctional(c.default || c))
export const CollectButton = () => import('../../components/CollectButton.vue' /* webpackChunkName: "components/collect-button" */).then(c => wrapFunctional(c.default || c))
export const CopyText = () => import('../../components/CopyText.vue' /* webpackChunkName: "components/copy-text" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../components/Dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogLayout = () => import('../../components/DialogLayout.vue' /* webpackChunkName: "components/dialog-layout" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const FollowerDialog = () => import('../../components/FollowerDialog.vue' /* webpackChunkName: "components/follower-dialog" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormField = () => import('../../components/FormField.vue' /* webpackChunkName: "components/form-field" */).then(c => wrapFunctional(c.default || c))
export const GutenbergEntrance = () => import('../../components/GutenbergEntrance.vue' /* webpackChunkName: "components/gutenberg-entrance" */).then(c => wrapFunctional(c.default || c))
export const IndexPageFeaturedNFTBook = () => import('../../components/IndexPageFeaturedNFTBook.vue' /* webpackChunkName: "components/index-page-featured-n-f-t-book" */).then(c => wrapFunctional(c.default || c))
export const IndexPageFeaturedNFTBookDeco = () => import('../../components/IndexPageFeaturedNFTBookDeco.vue' /* webpackChunkName: "components/index-page-featured-n-f-t-book-deco" */).then(c => wrapFunctional(c.default || c))
export const IndexPageFreeCollectCTA = () => import('../../components/IndexPageFreeCollectCTA.vue' /* webpackChunkName: "components/index-page-free-collect-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTrendingNFTListPlaceholder = () => import('../../components/IndexPageTrendingNFTListPlaceholder.vue' /* webpackChunkName: "components/index-page-trending-n-f-t-list-placeholder" */).then(c => wrapFunctional(c.default || c))
export const Label = () => import('../../components/Label.vue' /* webpackChunkName: "components/label" */).then(c => wrapFunctional(c.default || c))
export const LikeButtonAnimation = () => import('../../components/LikeButtonAnimation.vue' /* webpackChunkName: "components/like-button-animation" */).then(c => wrapFunctional(c.default || c))
export const LinkV2 = () => import('../../components/LinkV2.vue' /* webpackChunkName: "components/link-v2" */).then(c => wrapFunctional(c.default || c))
export const Markdown = () => import('../../components/Markdown.vue' /* webpackChunkName: "components/markdown" */).then(c => wrapFunctional(c.default || c))
export const MenuButton = () => import('../../components/MenuButton.vue' /* webpackChunkName: "components/menu-button" */).then(c => wrapFunctional(c.default || c))
export const MenuButtonDivider = () => import('../../components/MenuButtonDivider.vue' /* webpackChunkName: "components/menu-button-divider" */).then(c => wrapFunctional(c.default || c))
export const MenuIcon = () => import('../../components/MenuIcon.vue' /* webpackChunkName: "components/menu-icon" */).then(c => wrapFunctional(c.default || c))
export const MenuItem = () => import('../../components/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c))
export const MenuList = () => import('../../components/MenuList.vue' /* webpackChunkName: "components/menu-list" */).then(c => wrapFunctional(c.default || c))
export const MobileStickyCard = () => import('../../components/MobileStickyCard.vue' /* webpackChunkName: "components/mobile-sticky-card" */).then(c => wrapFunctional(c.default || c))
export const NFTCover = () => import('../../components/NFTCover.vue' /* webpackChunkName: "components/n-f-t-cover" */).then(c => wrapFunctional(c.default || c))
export const NFTEditionSelect = () => import('../../components/NFTEditionSelect.vue' /* webpackChunkName: "components/n-f-t-edition-select" */).then(c => wrapFunctional(c.default || c))
export const NFTEditionSelectItem = () => import('../../components/NFTEditionSelectItem.vue' /* webpackChunkName: "components/n-f-t-edition-select-item" */).then(c => wrapFunctional(c.default || c))
export const NFTFreeFallGraphic = () => import('../../components/NFTFreeFallGraphic.vue' /* webpackChunkName: "components/n-f-t-free-fall-graphic" */).then(c => wrapFunctional(c.default || c))
export const NFTStockLabel = () => import('../../components/NFTStockLabel.vue' /* webpackChunkName: "components/n-f-t-stock-label" */).then(c => wrapFunctional(c.default || c))
export const NFTStoreCarousel = () => import('../../components/NFTStoreCarousel.vue' /* webpackChunkName: "components/n-f-t-store-carousel" */).then(c => wrapFunctional(c.default || c))
export const NFTViewOptionList = () => import('../../components/NFTViewOptionList.vue' /* webpackChunkName: "components/n-f-t-view-option-list" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const ProgressIndicator = () => import('../../components/ProgressIndicator.vue' /* webpackChunkName: "components/progress-indicator" */).then(c => wrapFunctional(c.default || c))
export const Separator = () => import('../../components/Separator.vue' /* webpackChunkName: "components/separator" */).then(c => wrapFunctional(c.default || c))
export const SettingsPageHeader = () => import('../../components/SettingsPageHeader.vue' /* webpackChunkName: "components/settings-page-header" */).then(c => wrapFunctional(c.default || c))
export const ShareButton = () => import('../../components/ShareButton.vue' /* webpackChunkName: "components/share-button" */).then(c => wrapFunctional(c.default || c))
export const ShowMore = () => import('../../components/ShowMore.vue' /* webpackChunkName: "components/show-more" */).then(c => wrapFunctional(c.default || c))
export const SiteHeader = () => import('../../components/SiteHeader.vue' /* webpackChunkName: "components/site-header" */).then(c => wrapFunctional(c.default || c))
export const SiteMenuForMobile = () => import('../../components/SiteMenuForMobile.vue' /* webpackChunkName: "components/site-menu-for-mobile" */).then(c => wrapFunctional(c.default || c))
export const Snackbar = () => import('../../components/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(c => wrapFunctional(c.default || c))
export const SocialFeedItem = () => import('../../components/SocialFeedItem.vue' /* webpackChunkName: "components/social-feed-item" */).then(c => wrapFunctional(c.default || c))
export const SocialFeedPlaceholder = () => import('../../components/SocialFeedPlaceholder.vue' /* webpackChunkName: "components/social-feed-placeholder" */).then(c => wrapFunctional(c.default || c))
export const Stepper = () => import('../../components/Stepper.vue' /* webpackChunkName: "components/stepper" */).then(c => wrapFunctional(c.default || c))
export const Substack = () => import('../../components/Substack.vue' /* webpackChunkName: "components/substack" */).then(c => wrapFunctional(c.default || c))
export const TextField = () => import('../../components/TextField.vue' /* webpackChunkName: "components/text-field" */).then(c => wrapFunctional(c.default || c))
export const ToolTips = () => import('../../components/ToolTips.vue' /* webpackChunkName: "components/tool-tips" */).then(c => wrapFunctional(c.default || c))
export const TxModal = () => import('../../components/TxModal.vue' /* webpackChunkName: "components/tx-modal" */).then(c => wrapFunctional(c.default || c))
export const UserInfoCard = () => import('../../components/UserInfoCard.vue' /* webpackChunkName: "components/user-info-card" */).then(c => wrapFunctional(c.default || c))
export const AboutPage = () => import('../../components/AboutPage/AboutPage.vue' /* webpackChunkName: "components/about-page" */).then(c => wrapFunctional(c.default || c))
export const AppDownloadBadges = () => import('../../components/AppDownloadBadges/AppDownloadBadges.vue' /* webpackChunkName: "components/app-download-badges" */).then(c => wrapFunctional(c.default || c))
export const AppScreenshotsViewer = () => import('../../components/AppScreenshotsViewer/AppScreenshotsViewer.vue' /* webpackChunkName: "components/app-screenshots-viewer" */).then(c => wrapFunctional(c.default || c))
export const AsyncIndexPageTrendingNFTList = () => import('../../components/Async/IndexPageTrendingNFTList.vue' /* webpackChunkName: "components/async-index-page-trending-n-f-t-list" */).then(c => wrapFunctional(c.default || c))
export const AttentionSign = () => import('../../components/Attention/Sign.vue' /* webpackChunkName: "components/attention-sign" */).then(c => wrapFunctional(c.default || c))
export const Bodhisattva18BannerCTA = () => import('../../components/Bodhisattva18/BannerCTA.vue' /* webpackChunkName: "components/bodhisattva18-banner-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const CardBrand = () => import('../../components/CardBrand/CardBrand.vue' /* webpackChunkName: "components/card-brand" */).then(c => wrapFunctional(c.default || c))
export const CivicLikerV3Dashboard = () => import('../../components/CivicLikerV3/Dashboard.vue' /* webpackChunkName: "components/civic-liker-v3-dashboard" */).then(c => wrapFunctional(c.default || c))
export const CivicLikerV3Header = () => import('../../components/CivicLikerV3/Header.vue' /* webpackChunkName: "components/civic-liker-v3-header" */).then(c => wrapFunctional(c.default || c))
export const CivicLikerV3Page = () => import('../../components/CivicLikerV3/Page.vue' /* webpackChunkName: "components/civic-liker-v3-page" */).then(c => wrapFunctional(c.default || c))
export const CivicLikerV3PureDashboard = () => import('../../components/CivicLikerV3/PureDashboard.vue' /* webpackChunkName: "components/civic-liker-v3-pure-dashboard" */).then(c => wrapFunctional(c.default || c))
export const CivicLikerV3StepSection = () => import('../../components/CivicLikerV3/StepSection.vue' /* webpackChunkName: "components/civic-liker-v3-step-section" */).then(c => wrapFunctional(c.default || c))
export const CommunityCTA = () => import('../../components/CommunityCTA/CommunityCTA.vue' /* webpackChunkName: "components/community-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const EventModalTransfer = () => import('../../components/EventModal/Transfer.vue' /* webpackChunkName: "components/event-modal-transfer" */).then(c => wrapFunctional(c.default || c))
export const GetAppPage = () => import('../../components/GetAppPage/GetAppPage.vue' /* webpackChunkName: "components/get-app-page" */).then(c => wrapFunctional(c.default || c))
export const GutenbergButton = () => import('../../components/Gutenberg/Button.vue' /* webpackChunkName: "components/gutenberg-button" */).then(c => wrapFunctional(c.default || c))
export const GutenbergDownloadItem = () => import('../../components/Gutenberg/DownloadItem.vue' /* webpackChunkName: "components/gutenberg-download-item" */).then(c => wrapFunctional(c.default || c))
export const IconASC = () => import('../../components/Icon/ASC.vue' /* webpackChunkName: "components/icon-a-s-c" */).then(c => wrapFunctional(c.default || c))
export const IconActivity = () => import('../../components/Icon/Activity.vue' /* webpackChunkName: "components/icon-activity" */).then(c => wrapFunctional(c.default || c))
export const IconAdd = () => import('../../components/Icon/Add.vue' /* webpackChunkName: "components/icon-add" */).then(c => wrapFunctional(c.default || c))
export const IconArrowDown = () => import('../../components/Icon/ArrowDown.vue' /* webpackChunkName: "components/icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const IconArrowLeft = () => import('../../components/Icon/ArrowLeft.vue' /* webpackChunkName: "components/icon-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRight = () => import('../../components/Icon/ArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconArticle = () => import('../../components/Icon/Article.vue' /* webpackChunkName: "components/icon-article" */).then(c => wrapFunctional(c.default || c))
export const IconAttention = () => import('../../components/Icon/Attention.vue' /* webpackChunkName: "components/icon-attention" */).then(c => wrapFunctional(c.default || c))
export const IconBell = () => import('../../components/Icon/Bell.vue' /* webpackChunkName: "components/icon-bell" */).then(c => wrapFunctional(c.default || c))
export const IconBin = () => import('../../components/Icon/Bin.vue' /* webpackChunkName: "components/icon-bin" */).then(c => wrapFunctional(c.default || c))
export const IconBook = () => import('../../components/Icon/Book.vue' /* webpackChunkName: "components/icon-book" */).then(c => wrapFunctional(c.default || c))
export const IconBookmarkFilled = () => import('../../components/Icon/BookmarkFilled.vue' /* webpackChunkName: "components/icon-bookmark-filled" */).then(c => wrapFunctional(c.default || c))
export const IconBookmarkOutlined = () => import('../../components/Icon/BookmarkOutlined.vue' /* webpackChunkName: "components/icon-bookmark-outlined" */).then(c => wrapFunctional(c.default || c))
export const IconCheck = () => import('../../components/Icon/Check.vue' /* webpackChunkName: "components/icon-check" */).then(c => wrapFunctional(c.default || c))
export const IconCheckBoxOff = () => import('../../components/Icon/CheckBoxOff.vue' /* webpackChunkName: "components/icon-check-box-off" */).then(c => wrapFunctional(c.default || c))
export const IconCheckBoxOn = () => import('../../components/Icon/CheckBoxOn.vue' /* webpackChunkName: "components/icon-check-box-on" */).then(c => wrapFunctional(c.default || c))
export const IconCheckMini = () => import('../../components/Icon/CheckMini.vue' /* webpackChunkName: "components/icon-check-mini" */).then(c => wrapFunctional(c.default || c))
export const IconCircle = () => import('../../components/Icon/Circle.vue' /* webpackChunkName: "components/icon-circle" */).then(c => wrapFunctional(c.default || c))
export const IconCircleCheck = () => import('../../components/Icon/CircleCheck.vue' /* webpackChunkName: "components/icon-circle-check" */).then(c => wrapFunctional(c.default || c))
export const IconClock = () => import('../../components/Icon/Clock.vue' /* webpackChunkName: "components/icon-clock" */).then(c => wrapFunctional(c.default || c))
export const IconClose = () => import('../../components/Icon/Close.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconCode = () => import('../../components/Icon/Code.vue' /* webpackChunkName: "components/icon-code" */).then(c => wrapFunctional(c.default || c))
export const IconCollection = () => import('../../components/Icon/Collection.vue' /* webpackChunkName: "components/icon-collection" */).then(c => wrapFunctional(c.default || c))
export const IconCommunity = () => import('../../components/Icon/Community.vue' /* webpackChunkName: "components/icon-community" */).then(c => wrapFunctional(c.default || c))
export const IconCreativeWork = () => import('../../components/Icon/CreativeWork.vue' /* webpackChunkName: "components/icon-creative-work" */).then(c => wrapFunctional(c.default || c))
export const IconCross = () => import('../../components/Icon/Cross.vue' /* webpackChunkName: "components/icon-cross" */).then(c => wrapFunctional(c.default || c))
export const IconDESC = () => import('../../components/Icon/DESC.vue' /* webpackChunkName: "components/icon-d-e-s-c" */).then(c => wrapFunctional(c.default || c))
export const IconDownload = () => import('../../components/Icon/Download.vue' /* webpackChunkName: "components/icon-download" */).then(c => wrapFunctional(c.default || c))
export const IconEdit = () => import('../../components/Icon/Edit.vue' /* webpackChunkName: "components/icon-edit" */).then(c => wrapFunctional(c.default || c))
export const IconEllipse = () => import('../../components/Icon/Ellipse.vue' /* webpackChunkName: "components/icon-ellipse" */).then(c => wrapFunctional(c.default || c))
export const IconEmail = () => import('../../components/Icon/Email.vue' /* webpackChunkName: "components/icon-email" */).then(c => wrapFunctional(c.default || c))
export const IconError = () => import('../../components/Icon/Error.vue' /* webpackChunkName: "components/icon-error" */).then(c => wrapFunctional(c.default || c))
export const IconExchange = () => import('../../components/Icon/Exchange.vue' /* webpackChunkName: "components/icon-exchange" */).then(c => wrapFunctional(c.default || c))
export const IconEye = () => import('../../components/Icon/Eye.vue' /* webpackChunkName: "components/icon-eye" */).then(c => wrapFunctional(c.default || c))
export const IconFilter = () => import('../../components/Icon/Filter.vue' /* webpackChunkName: "components/icon-filter" */).then(c => wrapFunctional(c.default || c))
export const IconFlare = () => import('../../components/Icon/Flare.vue' /* webpackChunkName: "components/icon-flare" */).then(c => wrapFunctional(c.default || c))
export const IconFolder = () => import('../../components/Icon/Folder.vue' /* webpackChunkName: "components/icon-folder" */).then(c => wrapFunctional(c.default || c))
export const IconGift = () => import('../../components/Icon/Gift.vue' /* webpackChunkName: "components/icon-gift" */).then(c => wrapFunctional(c.default || c))
export const IconHelp = () => import('../../components/Icon/Help.vue' /* webpackChunkName: "components/icon-help" */).then(c => wrapFunctional(c.default || c))
export const IconHide = () => import('../../components/Icon/Hide.vue' /* webpackChunkName: "components/icon-hide" */).then(c => wrapFunctional(c.default || c))
export const IconISCN = () => import('../../components/Icon/ISCN.vue' /* webpackChunkName: "components/icon-i-s-c-n" */).then(c => wrapFunctional(c.default || c))
export const IconIdentity = () => import('../../components/Icon/Identity.vue' /* webpackChunkName: "components/icon-identity" */).then(c => wrapFunctional(c.default || c))
export const IconInput = () => import('../../components/Icon/Input.vue' /* webpackChunkName: "components/icon-input" */).then(c => wrapFunctional(c.default || c))
export const IconKeplrMobile = () => import('../../components/Icon/KeplrMobile.vue' /* webpackChunkName: "components/icon-keplr-mobile" */).then(c => wrapFunctional(c.default || c))
export const IconLedger = () => import('../../components/Icon/Ledger.vue' /* webpackChunkName: "components/icon-ledger" */).then(c => wrapFunctional(c.default || c))
export const IconLikeEvent = () => import('../../components/Icon/LikeEvent.vue' /* webpackChunkName: "components/icon-like-event" */).then(c => wrapFunctional(c.default || c))
export const IconLikerLandApp = () => import('../../components/Icon/LikerLandApp.vue' /* webpackChunkName: "components/icon-liker-land-app" */).then(c => wrapFunctional(c.default || c))
export const IconLink8 = () => import('../../components/Icon/Link8.vue' /* webpackChunkName: "components/icon-link8" */).then(c => wrapFunctional(c.default || c))
export const IconLinkExternal = () => import('../../components/Icon/LinkExternal.vue' /* webpackChunkName: "components/icon-link-external" */).then(c => wrapFunctional(c.default || c))
export const IconLogin = () => import('../../components/Icon/Login.vue' /* webpackChunkName: "components/icon-login" */).then(c => wrapFunctional(c.default || c))
export const IconMessage = () => import('../../components/Icon/Message.vue' /* webpackChunkName: "components/icon-message" */).then(c => wrapFunctional(c.default || c))
export const IconMint = () => import('../../components/Icon/Mint.vue' /* webpackChunkName: "components/icon-mint" */).then(c => wrapFunctional(c.default || c))
export const IconNav = () => import('../../components/Icon/Nav.vue' /* webpackChunkName: "components/icon-nav" */).then(c => wrapFunctional(c.default || c))
export const IconNorthEast = () => import('../../components/Icon/NorthEast.vue' /* webpackChunkName: "components/icon-north-east" */).then(c => wrapFunctional(c.default || c))
export const IconNotify = () => import('../../components/Icon/Notify.vue' /* webpackChunkName: "components/icon-notify" */).then(c => wrapFunctional(c.default || c))
export const IconOpenInNew = () => import('../../components/Icon/OpenInNew.vue' /* webpackChunkName: "components/icon-open-in-new" */).then(c => wrapFunctional(c.default || c))
export const IconOwner = () => import('../../components/Icon/Owner.vue' /* webpackChunkName: "components/icon-owner" */).then(c => wrapFunctional(c.default || c))
export const IconPerson = () => import('../../components/Icon/Person.vue' /* webpackChunkName: "components/icon-person" */).then(c => wrapFunctional(c.default || c))
export const IconPersonMini = () => import('../../components/Icon/PersonMini.vue' /* webpackChunkName: "components/icon-person-mini" */).then(c => wrapFunctional(c.default || c))
export const IconPlaceholder = () => import('../../components/Icon/Placeholder.vue' /* webpackChunkName: "components/icon-placeholder" */).then(c => wrapFunctional(c.default || c))
export const IconPrice = () => import('../../components/Icon/Price.vue' /* webpackChunkName: "components/icon-price" */).then(c => wrapFunctional(c.default || c))
export const IconPriceMini = () => import('../../components/Icon/PriceMini.vue' /* webpackChunkName: "components/icon-price-mini" */).then(c => wrapFunctional(c.default || c))
export const IconQuestion = () => import('../../components/Icon/Question.vue' /* webpackChunkName: "components/icon-question" */).then(c => wrapFunctional(c.default || c))
export const IconRefresh = () => import('../../components/Icon/Refresh.vue' /* webpackChunkName: "components/icon-refresh" */).then(c => wrapFunctional(c.default || c))
export const IconScrollToTop = () => import('../../components/Icon/ScrollToTop.vue' /* webpackChunkName: "components/icon-scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const IconSearch = () => import('../../components/Icon/Search.vue' /* webpackChunkName: "components/icon-search" */).then(c => wrapFunctional(c.default || c))
export const IconSetting = () => import('../../components/Icon/Setting.vue' /* webpackChunkName: "components/icon-setting" */).then(c => wrapFunctional(c.default || c))
export const IconShare = () => import('../../components/Icon/Share.vue' /* webpackChunkName: "components/icon-share" */).then(c => wrapFunctional(c.default || c))
export const IconSkipNext = () => import('../../components/Icon/SkipNext.vue' /* webpackChunkName: "components/icon-skip-next" */).then(c => wrapFunctional(c.default || c))
export const IconSorter = () => import('../../components/Icon/Sorter.vue' /* webpackChunkName: "components/icon-sorter" */).then(c => wrapFunctional(c.default || c))
export const IconStartFilled = () => import('../../components/Icon/StartFilled.vue' /* webpackChunkName: "components/icon-start-filled" */).then(c => wrapFunctional(c.default || c))
export const IconStartOutlined = () => import('../../components/Icon/StartOutlined.vue' /* webpackChunkName: "components/icon-start-outlined" */).then(c => wrapFunctional(c.default || c))
export const IconStats = () => import('../../components/Icon/Stats.vue' /* webpackChunkName: "components/icon-stats" */).then(c => wrapFunctional(c.default || c))
export const IconStepDot = () => import('../../components/Icon/StepDot.vue' /* webpackChunkName: "components/icon-step-dot" */).then(c => wrapFunctional(c.default || c))
export const IconSupply = () => import('../../components/Icon/Supply.vue' /* webpackChunkName: "components/icon-supply" */).then(c => wrapFunctional(c.default || c))
export const IconTown = () => import('../../components/Icon/Town.vue' /* webpackChunkName: "components/icon-town" */).then(c => wrapFunctional(c.default || c))
export const IconTransfer = () => import('../../components/Icon/Transfer.vue' /* webpackChunkName: "components/icon-transfer" */).then(c => wrapFunctional(c.default || c))
export const IconTransferMini = () => import('../../components/Icon/TransferMini.vue' /* webpackChunkName: "components/icon-transfer-mini" */).then(c => wrapFunctional(c.default || c))
export const IconView = () => import('../../components/Icon/View.vue' /* webpackChunkName: "components/icon-view" */).then(c => wrapFunctional(c.default || c))
export const IconWallet = () => import('../../components/Icon/Wallet.vue' /* webpackChunkName: "components/icon-wallet" */).then(c => wrapFunctional(c.default || c))
export const IconLogOut = () => import('../../components/Icon/logOut.vue' /* webpackChunkName: "components/icon-log-out" */).then(c => wrapFunctional(c.default || c))
export const IdentityAvatar = () => import('../../components/Identity/Identity.avatar.vue' /* webpackChunkName: "components/identity-avatar" */).then(c => wrapFunctional(c.default || c))
export const Identity = () => import('../../components/Identity/Identity.vue' /* webpackChunkName: "components/identity" */).then(c => wrapFunctional(c.default || c))
export const IndexPageFAQItem = () => import('../../components/IndexPage/FAQItem.vue' /* webpackChunkName: "components/index-page-f-a-q-item" */).then(c => wrapFunctional(c.default || c))
export const IndexPageHeading = () => import('../../components/IndexPage/Heading.vue' /* webpackChunkName: "components/index-page-heading" */).then(c => wrapFunctional(c.default || c))
export const IndexPageHeadingContentWithCTA = () => import('../../components/IndexPage/HeadingContentWithCTA.vue' /* webpackChunkName: "components/index-page-heading-content-with-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const IndexPageQuote = () => import('../../components/IndexPage/Quote.vue' /* webpackChunkName: "components/index-page-quote" */).then(c => wrapFunctional(c.default || c))
export const LegacyButton = () => import('../../components/LegacyButton/Button.vue' /* webpackChunkName: "components/legacy-button" */).then(c => wrapFunctional(c.default || c))
export const LegacyButtonGroup = () => import('../../components/LegacyButton/ButtonGroup.vue' /* webpackChunkName: "components/legacy-button-group" */).then(c => wrapFunctional(c.default || c))
export const ListingPageBooxBanner = () => import('../../components/ListingPage/BooxBanner.vue' /* webpackChunkName: "components/listing-page-boox-banner" */).then(c => wrapFunctional(c.default || c))
export const ListingPageDialog = () => import('../../components/ListingPage/Dialog.vue' /* webpackChunkName: "components/listing-page-dialog" */).then(c => wrapFunctional(c.default || c))
export const ListingPageMobileSortingSection = () => import('../../components/ListingPage/MobileSortingSection.vue' /* webpackChunkName: "components/listing-page-mobile-sorting-section" */).then(c => wrapFunctional(c.default || c))
export const ListingPageOptionList = () => import('../../components/ListingPage/OptionList.vue' /* webpackChunkName: "components/listing-page-option-list" */).then(c => wrapFunctional(c.default || c))
export const ListingPageQASection = () => import('../../components/ListingPage/QASection.vue' /* webpackChunkName: "components/listing-page-q-a-section" */).then(c => wrapFunctional(c.default || c))
export const ListingPageToggleButton = () => import('../../components/ListingPage/ToggleButton.vue' /* webpackChunkName: "components/listing-page-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const ListingPageToggleButtonCheckboxIcon = () => import('../../components/ListingPage/ToggleButtonCheckboxIcon.vue' /* webpackChunkName: "components/listing-page-toggle-button-checkbox-icon" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPageCTASection = () => import('../../components/NFTAboutPage/CTASection.vue' /* webpackChunkName: "components/n-f-t-about-page-c-t-a-section" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPageFAQItem = () => import('../../components/NFTAboutPage/FAQItem.vue' /* webpackChunkName: "components/n-f-t-about-page-f-a-q-item" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPageFAQSection = () => import('../../components/NFTAboutPage/FAQSection.vue' /* webpackChunkName: "components/n-f-t-about-page-f-a-q-section" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPageHeroSection = () => import('../../components/NFTAboutPage/HeroSection.vue' /* webpackChunkName: "components/n-f-t-about-page-hero-section" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPagePortalSection = () => import('../../components/NFTAboutPage/PortalSection.vue' /* webpackChunkName: "components/n-f-t-about-page-portal-section" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPagePortfolioSection = () => import('../../components/NFTAboutPage/PortfolioSection.vue' /* webpackChunkName: "components/n-f-t-about-page-portfolio-section" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPagePricingSection = () => import('../../components/NFTAboutPage/PricingSection.vue' /* webpackChunkName: "components/n-f-t-about-page-pricing-section" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPageWidgetSection = () => import('../../components/NFTAboutPage/WidgetSection.vue' /* webpackChunkName: "components/n-f-t-about-page-widget-section" */).then(c => wrapFunctional(c.default || c))
export const NFTAboutPage = () => import('../../components/NFTAboutPage/index.vue' /* webpackChunkName: "components/n-f-t-about-page" */).then(c => wrapFunctional(c.default || c))
export const NFTBookCover = () => import('../../components/NFTBook/Cover.vue' /* webpackChunkName: "components/n-f-t-book-cover" */).then(c => wrapFunctional(c.default || c))
export const NFTBookCoverWithFrame = () => import('../../components/NFTBook/CoverWithFrame.vue' /* webpackChunkName: "components/n-f-t-book-cover-with-frame" */).then(c => wrapFunctional(c.default || c))
export const NFTBookCrossSellDialog = () => import('../../components/NFTBook/CrossSellDialog.vue' /* webpackChunkName: "components/n-f-t-book-cross-sell-dialog" */).then(c => wrapFunctional(c.default || c))
export const NFTBookEditionCompareTableColumn = () => import('../../components/NFTBook/EditionCompareTableColumn.vue' /* webpackChunkName: "components/n-f-t-book-edition-compare-table-column" */).then(c => wrapFunctional(c.default || c))
export const NFTBookGiftDialog = () => import('../../components/NFTBook/GiftDialog.vue' /* webpackChunkName: "components/n-f-t-book-gift-dialog" */).then(c => wrapFunctional(c.default || c))
export const NFTBookHero = () => import('../../components/NFTBook/Hero.vue' /* webpackChunkName: "components/n-f-t-book-hero" */).then(c => wrapFunctional(c.default || c))
export const NFTBookHeroStatic = () => import('../../components/NFTBook/HeroStatic.vue' /* webpackChunkName: "components/n-f-t-book-hero-static" */).then(c => wrapFunctional(c.default || c))
export const NFTBookItemCard = () => import('../../components/NFTBook/ItemCard.vue' /* webpackChunkName: "components/n-f-t-book-item-card" */).then(c => wrapFunctional(c.default || c))
export const NFTBookItemCardV2 = () => import('../../components/NFTBook/ItemCardV2.vue' /* webpackChunkName: "components/n-f-t-book-item-card-v2" */).then(c => wrapFunctional(c.default || c))
export const NFTBookShelf = () => import('../../components/NFTBook/Shelf.vue' /* webpackChunkName: "components/n-f-t-book-shelf" */).then(c => wrapFunctional(c.default || c))
export const NFTBookSpecTable = () => import('../../components/NFTBook/SpecTable.vue' /* webpackChunkName: "components/n-f-t-book-spec-table" */).then(c => wrapFunctional(c.default || c))
export const NFTBookSpecTableItemAccessMethod = () => import('../../components/NFTBook/SpecTableItemAccessMethod.vue' /* webpackChunkName: "components/n-f-t-book-spec-table-item-access-method" */).then(c => wrapFunctional(c.default || c))
export const NFTBookSpecTableItemAvailableFormat = () => import('../../components/NFTBook/SpecTableItemAvailableFormat.vue' /* webpackChunkName: "components/n-f-t-book-spec-table-item-available-format" */).then(c => wrapFunctional(c.default || c))
export const NFTBookSpecTableLabel = () => import('../../components/NFTBook/SpecTableLabel.vue' /* webpackChunkName: "components/n-f-t-book-spec-table-label" */).then(c => wrapFunctional(c.default || c))
export const NFTBookTippingDialog = () => import('../../components/NFTBook/TippingDialog.vue' /* webpackChunkName: "components/n-f-t-book-tipping-dialog" */).then(c => wrapFunctional(c.default || c))
export const NFTBookTippingInput = () => import('../../components/NFTBook/TippingInput.vue' /* webpackChunkName: "components/n-f-t-book-tipping-input" */).then(c => wrapFunctional(c.default || c))
export const NFTCampaignHero = () => import('../../components/NFTCampaign/Hero.vue' /* webpackChunkName: "components/n-f-t-campaign-hero" */).then(c => wrapFunctional(c.default || c))
export const NFTCampaignItem = () => import('../../components/NFTCampaign/Item.vue' /* webpackChunkName: "components/n-f-t-campaign-item" */).then(c => wrapFunctional(c.default || c))
export const NFTCampaignItemBase = () => import('../../components/NFTCampaign/ItemBase.vue' /* webpackChunkName: "components/n-f-t-campaign-item-base" */).then(c => wrapFunctional(c.default || c))
export const NFTClaimMainSection = () => import('../../components/NFTClaim/MainSection.vue' /* webpackChunkName: "components/n-f-t-claim-main-section" */).then(c => wrapFunctional(c.default || c))
export const NFTClaimMessageBlock = () => import('../../components/NFTClaim/MessageBlock.vue' /* webpackChunkName: "components/n-f-t-claim-message-block" */).then(c => wrapFunctional(c.default || c))
export const NFTClaimOptionList = () => import('../../components/NFTClaim/OptionList.vue' /* webpackChunkName: "components/n-f-t-claim-option-list" */).then(c => wrapFunctional(c.default || c))
export const NFTCollectionItemCard = () => import('../../components/NFTCollection/ItemCard.vue' /* webpackChunkName: "components/n-f-t-collection-item-card" */).then(c => wrapFunctional(c.default || c))
export const NFTFeatured = () => import('../../components/NFTFeatured/index.vue' /* webpackChunkName: "components/n-f-t-featured" */).then(c => wrapFunctional(c.default || c))
export const NFTGemWrapper = () => import('../../components/NFTGem/Wrapper.vue' /* webpackChunkName: "components/n-f-t-gem-wrapper" */).then(c => wrapFunctional(c.default || c))
export const NFTGem = () => import('../../components/NFTGem/index.vue' /* webpackChunkName: "components/n-f-t-gem" */).then(c => wrapFunctional(c.default || c))
export const NFTMessageIdentity = () => import('../../components/NFTMessage/Identity.vue' /* webpackChunkName: "components/n-f-t-message-identity" */).then(c => wrapFunctional(c.default || c))
export const NFTMessageText = () => import('../../components/NFTMessage/Text.vue' /* webpackChunkName: "components/n-f-t-message-text" */).then(c => wrapFunctional(c.default || c))
export const NFTMessage = () => import('../../components/NFTMessage/index.vue' /* webpackChunkName: "components/n-f-t-message" */).then(c => wrapFunctional(c.default || c))
export const NFTPageCollectCTA = () => import('../../components/NFTPage/CollectCTA.vue' /* webpackChunkName: "components/n-f-t-page-collect-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const NFTPageControlBar = () => import('../../components/NFTPage/ControlBar.vue' /* webpackChunkName: "components/n-f-t-page-control-bar" */).then(c => wrapFunctional(c.default || c))
export const NFTPageMetadataSection = () => import('../../components/NFTPage/MetadataSection.vue' /* webpackChunkName: "components/n-f-t-page-metadata-section" */).then(c => wrapFunctional(c.default || c))
export const NFTPageOwning = () => import('../../components/NFTPage/Owning.vue' /* webpackChunkName: "components/n-f-t-page-owning" */).then(c => wrapFunctional(c.default || c))
export const NFTPageOwningSection = () => import('../../components/NFTPage/OwningSection.vue' /* webpackChunkName: "components/n-f-t-page-owning-section" */).then(c => wrapFunctional(c.default || c))
export const NFTPagePreviewCard = () => import('../../components/NFTPage/PreviewCard.vue' /* webpackChunkName: "components/n-f-t-page-preview-card" */).then(c => wrapFunctional(c.default || c))
export const NFTPagePriceSection = () => import('../../components/NFTPage/PriceSection.vue' /* webpackChunkName: "components/n-f-t-page-price-section" */).then(c => wrapFunctional(c.default || c))
export const NFTPagePrimitiveClassInfoSection = () => import('../../components/NFTPage/PrimitiveClassInfoSection.vue' /* webpackChunkName: "components/n-f-t-page-primitive-class-info-section" */).then(c => wrapFunctional(c.default || c))
export const NFTPageRecommendation = () => import('../../components/NFTPage/Recommendation.vue' /* webpackChunkName: "components/n-f-t-page-recommendation" */).then(c => wrapFunctional(c.default || c))
export const NFTPageSupplySection = () => import('../../components/NFTPage/SupplySection.vue' /* webpackChunkName: "components/n-f-t-page-supply-section" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioBase = () => import('../../components/NFTPortfolio/Base.vue' /* webpackChunkName: "components/n-f-t-portfolio-base" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioCard = () => import('../../components/NFTPortfolio/Card.vue' /* webpackChunkName: "components/n-f-t-portfolio-card" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioEmpty = () => import('../../components/NFTPortfolio/Empty.vue' /* webpackChunkName: "components/n-f-t-portfolio-empty" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioFilterDropdown = () => import('../../components/NFTPortfolio/FilterDropdown.vue' /* webpackChunkName: "components/n-f-t-portfolio-filter-dropdown" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioFilterInput = () => import('../../components/NFTPortfolio/FilterInput.vue' /* webpackChunkName: "components/n-f-t-portfolio-filter-input" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioItem = () => import('../../components/NFTPortfolio/Item.vue' /* webpackChunkName: "components/n-f-t-portfolio-item" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioItemPlaceholder = () => import('../../components/NFTPortfolio/ItemPlaceholder.vue' /* webpackChunkName: "components/n-f-t-portfolio-item-placeholder" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioMainView = () => import('../../components/NFTPortfolio/MainView.vue' /* webpackChunkName: "components/n-f-t-portfolio-main-view" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioSubscriptionForm = () => import('../../components/NFTPortfolio/SubscriptionForm.vue' /* webpackChunkName: "components/n-f-t-portfolio-subscription-form" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioTopUsersList = () => import('../../components/NFTPortfolio/TopUsersList.vue' /* webpackChunkName: "components/n-f-t-portfolio-top-users-list" */).then(c => wrapFunctional(c.default || c))
export const NFTPortfolioUserInfo = () => import('../../components/NFTPortfolio/UserInfo.vue' /* webpackChunkName: "components/n-f-t-portfolio-user-info" */).then(c => wrapFunctional(c.default || c))
export const NFTSupplyRow = () => import('../../components/NFTSupply/Row.vue' /* webpackChunkName: "components/n-f-t-supply-row" */).then(c => wrapFunctional(c.default || c))
export const NFTSupplySlot = () => import('../../components/NFTSupply/Slot.vue' /* webpackChunkName: "components/n-f-t-supply-slot" */).then(c => wrapFunctional(c.default || c))
export const NFTSupplyTable = () => import('../../components/NFTSupply/Table.vue' /* webpackChunkName: "components/n-f-t-supply-table" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetBaseCard = () => import('../../components/NFTWidget/BaseCard.vue' /* webpackChunkName: "components/n-f-t-widget-base-card" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetCollectActionBar = () => import('../../components/NFTWidget/CollectActionBar.vue' /* webpackChunkName: "components/n-f-t-widget-collect-action-bar" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetContentPreview = () => import('../../components/NFTWidget/ContentPreview.vue' /* webpackChunkName: "components/n-f-t-widget-content-preview" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetLikeActionBar = () => import('../../components/NFTWidget/LikeActionBar.vue' /* webpackChunkName: "components/n-f-t-widget-like-action-bar" */).then(c => wrapFunctional(c.default || c))
export const NFTWidget = () => import('../../components/NFTWidget/index.vue' /* webpackChunkName: "components/n-f-t-widget" */).then(c => wrapFunctional(c.default || c))
export const Placeholder = () => import('../../components/Placeholder/Placeholder.vue' /* webpackChunkName: "components/placeholder" */).then(c => wrapFunctional(c.default || c))
export const SelectButton = () => import('../../components/SelectButton/SelectButton.vue' /* webpackChunkName: "components/select-button" */).then(c => wrapFunctional(c.default || c))
export const SettingsListItem = () => import('../../components/Settings/ListItem.vue' /* webpackChunkName: "components/settings-list-item" */).then(c => wrapFunctional(c.default || c))
export const SettingsNotificationConfig = () => import('../../components/Settings/NotificationConfig.vue' /* webpackChunkName: "components/settings-notification-config" */).then(c => wrapFunctional(c.default || c))
export const ShoppingCartListRow = () => import('../../components/ShoppingCart/ListRow.vue' /* webpackChunkName: "components/shopping-cart-list-row" */).then(c => wrapFunctional(c.default || c))
export const ShoppingCartSiteButton = () => import('../../components/ShoppingCart/SiteButton.vue' /* webpackChunkName: "components/shopping-cart-site-button" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/Slider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/Spinner/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const TippingCheckIcon = () => import('../../components/Tipping/CheckIcon.vue' /* webpackChunkName: "components/tipping-check-icon" */).then(c => wrapFunctional(c.default || c))
export const UserStatsController = () => import('../../components/UserStats/Controller.vue' /* webpackChunkName: "components/user-stats-controller" */).then(c => wrapFunctional(c.default || c))
export const UserStatsGem = () => import('../../components/UserStats/Gem.vue' /* webpackChunkName: "components/user-stats-gem" */).then(c => wrapFunctional(c.default || c))
export const UserStatsIncomeDetailsDialog = () => import('../../components/UserStats/IncomeDetailsDialog.vue' /* webpackChunkName: "components/user-stats-income-details-dialog" */).then(c => wrapFunctional(c.default || c))
export const UserStatsIncomeMenuItem = () => import('../../components/UserStats/IncomeMenuItem.vue' /* webpackChunkName: "components/user-stats-income-menu-item" */).then(c => wrapFunctional(c.default || c))
export const UserStatsIncomeTable = () => import('../../components/UserStats/IncomeTable.vue' /* webpackChunkName: "components/user-stats-income-table" */).then(c => wrapFunctional(c.default || c))
export const UserStatsItem = () => import('../../components/UserStats/Item.vue' /* webpackChunkName: "components/user-stats-item" */).then(c => wrapFunctional(c.default || c))
export const UserStatsMyDashboard = () => import('../../components/UserStats/MyDashboard.vue' /* webpackChunkName: "components/user-stats-my-dashboard" */).then(c => wrapFunctional(c.default || c))
export const UserStatsPortfolio = () => import('../../components/UserStats/Portfolio.vue' /* webpackChunkName: "components/user-stats-portfolio" */).then(c => wrapFunctional(c.default || c))
export const EventModalCollectMethodButton = () => import('../../components/EventModal/Collect/MethodButton.vue' /* webpackChunkName: "components/event-modal-collect-method-button" */).then(c => wrapFunctional(c.default || c))
export const EventModalCollectMethodIcon = () => import('../../components/EventModal/Collect/MethodIcon.vue' /* webpackChunkName: "components/event-modal-collect-method-icon" */).then(c => wrapFunctional(c.default || c))
export const EventModalCollect = () => import('../../components/EventModal/Collect/index.vue' /* webpackChunkName: "components/event-modal-collect" */).then(c => wrapFunctional(c.default || c))
export const NFTPageChainDataSectionMetadata = () => import('../../components/NFTPage/ChainDataSection/Metadata.vue' /* webpackChunkName: "components/n-f-t-page-chain-data-section-metadata" */).then(c => wrapFunctional(c.default || c))
export const NFTPageChainDataSection = () => import('../../components/NFTPage/ChainDataSection/index.vue' /* webpackChunkName: "components/n-f-t-page-chain-data-section" */).then(c => wrapFunctional(c.default || c))
export const NFTPageCollectorListItem = () => import('../../components/NFTPage/CollectorList/Item.vue' /* webpackChunkName: "components/n-f-t-page-collector-list-item" */).then(c => wrapFunctional(c.default || c))
export const NFTPageCollectorList = () => import('../../components/NFTPage/CollectorList/index.vue' /* webpackChunkName: "components/n-f-t-page-collector-list" */).then(c => wrapFunctional(c.default || c))
export const NFTPageEventListTable = () => import('../../components/NFTPage/EventList/Table.vue' /* webpackChunkName: "components/n-f-t-page-event-list-table" */).then(c => wrapFunctional(c.default || c))
export const NFTPageNFTSelect = () => import('../../components/NFTPage/NFTSelect/index.vue' /* webpackChunkName: "components/n-f-t-page-n-f-t-select" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetIconEye = () => import('../../components/NFTWidget/Icon/Eye.vue' /* webpackChunkName: "components/n-f-t-widget-icon-eye" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetIconInsertCoin = () => import('../../components/NFTWidget/Icon/InsertCoin.vue' /* webpackChunkName: "components/n-f-t-widget-icon-insert-coin" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetIconLike = () => import('../../components/NFTWidget/Icon/Like.vue' /* webpackChunkName: "components/n-f-t-widget-icon-like" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetIconNFT = () => import('../../components/NFTWidget/Icon/NFT.vue' /* webpackChunkName: "components/n-f-t-widget-icon-n-f-t" */).then(c => wrapFunctional(c.default || c))
export const NFTWidgetIconOwner = () => import('../../components/NFTWidget/Icon/Owner.vue' /* webpackChunkName: "components/n-f-t-widget-icon-owner" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
