import { render, staticRenderFns } from "./MethodButton.vue?vue&type=template&id=b1051064"
import script from "./MethodButton.vue?vue&type=script&lang=js"
export * from "./MethodButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventModalCollectMethodIcon: require('/home/runner/work/liker-land/liker-land/src/components/EventModal/Collect/MethodIcon.vue').default,IconArrowLeft: require('/home/runner/work/liker-land/liker-land/src/components/Icon/ArrowLeft.vue').default,ButtonV2: require('/home/runner/work/liker-land/liker-land/src/components/ButtonV2.vue').default})
