import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _27bc0284 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _280f46ec = () => interopDefault(import('../pages/index/bookmarks.vue' /* webpackChunkName: "pages/index/bookmarks" */))
const _55f6cd80 = () => interopDefault(import('../pages/index/following.vue' /* webpackChunkName: "pages/index/following" */))
const _56c00bbc = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _5667dbe0 = () => interopDefault(import('../pages/bookshelf/index.vue' /* webpackChunkName: "pages/bookshelf/index" */))
const _7d98f1d9 = () => interopDefault(import('../pages/civic/index.vue' /* webpackChunkName: "pages/civic/index" */))
const _7eaae24e = () => interopDefault(import('../pages/creators/index.vue' /* webpackChunkName: "pages/creators/index" */))
const _6429a7fa = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _10fd0f69 = () => interopDefault(import('../pages/eula.vue' /* webpackChunkName: "pages/eula" */))
const _65709765 = () => interopDefault(import('../pages/feed/index.vue' /* webpackChunkName: "pages/feed/index" */))
const _1977864f = () => interopDefault(import('../pages/getapp.vue' /* webpackChunkName: "pages/getapp" */))
const _36950495 = () => interopDefault(import('../pages/getapp/_action.vue' /* webpackChunkName: "pages/getapp/_action" */))
const _75043056 = () => interopDefault(import('../pages/gutenberg/index.vue' /* webpackChunkName: "pages/gutenberg/index" */))
const _17af2170 = () => interopDefault(import('../pages/keplr.vue' /* webpackChunkName: "pages/keplr" */))
const _507ba14e = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _66499952 = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _6ef2a007 = () => interopDefault(import('../pages/reader.vue' /* webpackChunkName: "pages/reader" */))
const _1b8457a0 = () => interopDefault(import('../pages/reader/epub.vue' /* webpackChunkName: "pages/reader/epub" */))
const _fab9152c = () => interopDefault(import('../pages/reader/pdf.vue' /* webpackChunkName: "pages/reader/pdf" */))
const _171c7867 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _cb5e54ac = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _e9e43076 = () => interopDefault(import('../pages/settings/civic/index.vue' /* webpackChunkName: "pages/settings/civic/index" */))
const _7186a2f7 = () => interopDefault(import('../pages/settings/email/index.vue' /* webpackChunkName: "pages/settings/email/index" */))
const _182ff02e = () => interopDefault(import('../pages/settings/following.vue' /* webpackChunkName: "pages/settings/following" */))
const _57585eb0 = () => interopDefault(import('../pages/settings/language.vue' /* webpackChunkName: "pages/settings/language" */))
const _6c0ad3b2 = () => interopDefault(import('../pages/settings/support.vue' /* webpackChunkName: "pages/settings/support" */))
const _69142327 = () => interopDefault(import('../pages/settings/email/verify/index.vue' /* webpackChunkName: "pages/settings/email/verify/index" */))
const _3f37d975 = () => interopDefault(import('../pages/settings/email/verify/_token.vue' /* webpackChunkName: "pages/settings/email/verify/_token" */))
const _8d6b6fe0 = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _14ad4cfa = () => interopDefault(import('../pages/writing-nft/index.vue' /* webpackChunkName: "pages/writing-nft/index" */))
const _000c1b60 = () => interopDefault(import('../pages/about/nft-book.vue' /* webpackChunkName: "pages/about/nft-book" */))
const _d7e6fb96 = () => interopDefault(import('../pages/about/writing-nft.vue' /* webpackChunkName: "pages/about/writing-nft" */))
const _226569cf = () => interopDefault(import('../pages/auth/redirect.vue' /* webpackChunkName: "pages/auth/redirect" */))
const _268708d9 = () => interopDefault(import('../pages/campaign/qrcode.vue' /* webpackChunkName: "pages/campaign/qrcode" */))
const _408e6c90 = () => interopDefault(import('../pages/campaign/writing-nft.vue' /* webpackChunkName: "pages/campaign/writing-nft" */))
const _8006bcf8 = () => interopDefault(import('../pages/_id/civic/index.vue' /* webpackChunkName: "" */))
const _11aab31b = () => interopDefault(import('../pages/civic/dashboard.vue' /* webpackChunkName: "pages/civic/dashboard" */))
const _f16c5a40 = () => interopDefault(import('../pages/civic/trial/index.vue' /* webpackChunkName: "pages/civic/trial/index" */))
const _5a6da03e = () => interopDefault(import('../pages/gutenberg/free-audio-books.vue' /* webpackChunkName: "pages/gutenberg/free-audio-books" */))
const _6fa96558 = () => interopDefault(import('../pages/nft/claim/index.vue' /* webpackChunkName: "pages/nft/claim/index" */))
const _063401ac = () => interopDefault(import('../pages/nft/gift/index.vue' /* webpackChunkName: "pages/nft/gift/index" */))
const _26f4ef18 = () => interopDefault(import('../pages/oauth/redirect.vue' /* webpackChunkName: "pages/oauth/redirect" */))
const _28ab58d2 = () => interopDefault(import('../pages/shopping-cart/book.vue' /* webpackChunkName: "pages/shopping-cart/book" */))
const _2d5ffc93 = () => interopDefault(import('../pages/shopping-cart/wnft.vue' /* webpackChunkName: "pages/shopping-cart/wnft" */))
const _43fa4eef = () => interopDefault(import('../pages/store/articles.vue' /* webpackChunkName: "pages/store/articles" */))
const _2ed60e6b = () => interopDefault(import('../pages/store/books/index.vue' /* webpackChunkName: "pages/store/books/index" */))
const _ba8bff40 = () => interopDefault(import('../pages/store/featured.vue' /* webpackChunkName: "pages/store/featured" */))
const _455b7ec4 = () => interopDefault(import('../pages/store/free.vue' /* webpackChunkName: "pages/store/free" */))
const _6f995cfe = () => interopDefault(import('../pages/store/paid.vue' /* webpackChunkName: "pages/store/paid" */))
const _16151b96 = () => interopDefault(import('../pages/writing-nft/about.vue' /* webpackChunkName: "pages/writing-nft/about" */))
const _191154df = () => interopDefault(import('../pages/nft/fiat/stripe.vue' /* webpackChunkName: "pages/nft/fiat/stripe" */))
const _d10c4fb6 = () => interopDefault(import('../pages/store/books/featured.vue' /* webpackChunkName: "pages/store/books/featured" */))
const _6f527a3a = () => interopDefault(import('../pages/store/books/free.vue' /* webpackChunkName: "pages/store/books/free" */))
const _5a9ddf43 = () => interopDefault(import('../pages/store/books/paid.vue' /* webpackChunkName: "pages/store/books/paid" */))
const _681ec7a5 = () => interopDefault(import('../pages/nft/class/_classId/index.vue' /* webpackChunkName: "pages/nft/class/_classId/index" */))
const _7d712841 = () => interopDefault(import('../pages/nft/collection/_collectionId/index.vue' /* webpackChunkName: "pages/nft/collection/_collectionId/index" */))
const _6b73cb28 = () => interopDefault(import('../pages/nft/iscn/_iscnId.vue' /* webpackChunkName: "pages/nft/iscn/_iscnId" */))
const _40c0b935 = () => interopDefault(import('../pages/nft/class/_classId/share/index.vue' /* webpackChunkName: "pages/nft/class/_classId/share/index" */))
const _a67fe096 = () => interopDefault(import('../pages/nft/class/_classId/_nftId.vue' /* webpackChunkName: "pages/nft/class/_classId/_nftId" */))
const _5d82b8a6 = () => interopDefault(import('../pages/nft/class/_classId/_nftId/message.vue' /* webpackChunkName: "pages/nft/class/_classId/_nftId/message" */))
const _70418aa9 = () => interopDefault(import('../pages/_id/index.vue' /* webpackChunkName: "pages/_id/index" */))
const _f65139e8 = () => interopDefault(import('../pages/_id/index/subscribe/_subscriptionId.vue' /* webpackChunkName: "pages/_id/index/subscribe/_subscriptionId" */))
const _415cca61 = () => interopDefault(import('../pages/_id/civic/register.vue' /* webpackChunkName: "pages/_id/civic/register" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _27bc0284,
    name: "index___en",
    children: [{
      path: "bookmarks",
      component: _280f46ec,
      name: "index-bookmarks___en"
    }, {
      path: "following",
      component: _55f6cd80,
      name: "index-following___en"
    }]
  }, {
    path: "/zh-Hant",
    component: _27bc0284,
    name: "index___zh-Hant",
    children: [{
      path: "bookmarks",
      component: _280f46ec,
      name: "index-bookmarks___zh-Hant"
    }, {
      path: "following",
      component: _55f6cd80,
      name: "index-following___zh-Hant"
    }]
  }, {
    path: "/en/about",
    component: _56c00bbc,
    name: "about___en"
  }, {
    path: "/en/bookshelf",
    component: _5667dbe0,
    name: "bookshelf___en"
  }, {
    path: "/en/civic",
    component: _7d98f1d9,
    name: "civic___en"
  }, {
    path: "/en/creators",
    component: _7eaae24e,
    name: "creators___en"
  }, {
    path: "/en/dashboard",
    component: _6429a7fa,
    name: "dashboard___en"
  }, {
    path: "/en/eula",
    component: _10fd0f69,
    name: "eula___en"
  }, {
    path: "/en/feed",
    component: _65709765,
    name: "feed___en"
  }, {
    path: "/en/getapp",
    component: _1977864f,
    name: "getapp___en",
    children: [{
      path: ":action?",
      component: _36950495,
      name: "getapp-action___en"
    }]
  }, {
    path: "/en/gutenberg",
    component: _75043056,
    name: "gutenberg___en"
  }, {
    path: "/en/keplr",
    component: _17af2170,
    name: "keplr___en"
  }, {
    path: "/en/logout",
    component: _507ba14e,
    name: "logout___en"
  }, {
    path: "/en/notifications",
    component: _66499952,
    name: "notifications___en"
  }, {
    path: "/en/reader",
    component: _6ef2a007,
    name: "reader___en",
    children: [{
      path: "epub",
      component: _1b8457a0,
      name: "reader-epub___en"
    }, {
      path: "pdf",
      component: _fab9152c,
      name: "reader-pdf___en"
    }]
  }, {
    path: "/en/settings",
    component: _171c7867,
    children: [{
      path: "",
      component: _cb5e54ac,
      name: "settings___en"
    }, {
      path: "civic",
      component: _e9e43076,
      name: "settings-civic___en"
    }, {
      path: "email",
      component: _7186a2f7,
      name: "settings-email___en"
    }, {
      path: "following",
      component: _182ff02e,
      name: "settings-following___en"
    }, {
      path: "language",
      component: _57585eb0,
      name: "settings-language___en"
    }, {
      path: "support",
      component: _6c0ad3b2,
      name: "settings-support___en"
    }, {
      path: "email/verify",
      component: _69142327,
      name: "settings-email-verify___en"
    }, {
      path: "email/verify/:token",
      component: _3f37d975,
      name: "settings-email-verify-token___en"
    }]
  }, {
    path: "/en/store",
    component: _8d6b6fe0,
    name: "store___en"
  }, {
    path: "/en/writing-nft",
    component: _14ad4cfa,
    name: "writing-nft___en"
  }, {
    path: "/zh-Hant/about",
    component: _56c00bbc,
    name: "about___zh-Hant"
  }, {
    path: "/zh-Hant/bookshelf",
    component: _5667dbe0,
    name: "bookshelf___zh-Hant"
  }, {
    path: "/zh-Hant/civic",
    component: _7d98f1d9,
    name: "civic___zh-Hant"
  }, {
    path: "/zh-Hant/creators",
    component: _7eaae24e,
    name: "creators___zh-Hant"
  }, {
    path: "/zh-Hant/dashboard",
    component: _6429a7fa,
    name: "dashboard___zh-Hant"
  }, {
    path: "/zh-Hant/eula",
    component: _10fd0f69,
    name: "eula___zh-Hant"
  }, {
    path: "/zh-Hant/feed",
    component: _65709765,
    name: "feed___zh-Hant"
  }, {
    path: "/zh-Hant/getapp",
    component: _1977864f,
    name: "getapp___zh-Hant",
    children: [{
      path: ":action?",
      component: _36950495,
      name: "getapp-action___zh-Hant"
    }]
  }, {
    path: "/zh-Hant/gutenberg",
    component: _75043056,
    name: "gutenberg___zh-Hant"
  }, {
    path: "/zh-Hant/keplr",
    component: _17af2170,
    name: "keplr___zh-Hant"
  }, {
    path: "/zh-Hant/logout",
    component: _507ba14e,
    name: "logout___zh-Hant"
  }, {
    path: "/zh-Hant/notifications",
    component: _66499952,
    name: "notifications___zh-Hant"
  }, {
    path: "/zh-Hant/reader",
    component: _6ef2a007,
    name: "reader___zh-Hant",
    children: [{
      path: "epub",
      component: _1b8457a0,
      name: "reader-epub___zh-Hant"
    }, {
      path: "pdf",
      component: _fab9152c,
      name: "reader-pdf___zh-Hant"
    }]
  }, {
    path: "/zh-Hant/settings",
    component: _171c7867,
    children: [{
      path: "",
      component: _cb5e54ac,
      name: "settings___zh-Hant"
    }, {
      path: "civic",
      component: _e9e43076,
      name: "settings-civic___zh-Hant"
    }, {
      path: "email",
      component: _7186a2f7,
      name: "settings-email___zh-Hant"
    }, {
      path: "following",
      component: _182ff02e,
      name: "settings-following___zh-Hant"
    }, {
      path: "language",
      component: _57585eb0,
      name: "settings-language___zh-Hant"
    }, {
      path: "support",
      component: _6c0ad3b2,
      name: "settings-support___zh-Hant"
    }, {
      path: "email/verify",
      component: _69142327,
      name: "settings-email-verify___zh-Hant"
    }, {
      path: "email/verify/:token",
      component: _3f37d975,
      name: "settings-email-verify-token___zh-Hant"
    }]
  }, {
    path: "/zh-Hant/store",
    component: _8d6b6fe0,
    name: "store___zh-Hant"
  }, {
    path: "/zh-Hant/writing-nft",
    component: _14ad4cfa,
    name: "writing-nft___zh-Hant"
  }, {
    path: "/en/about/nft-book",
    component: _000c1b60,
    name: "about-nft-book___en"
  }, {
    path: "/en/about/writing-nft",
    component: _d7e6fb96,
    name: "about-writing-nft___en"
  }, {
    path: "/en/auth/redirect",
    component: _226569cf,
    name: "auth-redirect___en"
  }, {
    path: "/en/campaign/qrcode",
    component: _268708d9,
    name: "campaign-qrcode___en"
  }, {
    path: "/en/campaign/writing-nft",
    component: _408e6c90,
    name: "campaign-writing-nft___en"
  }, {
    path: "/en/civic/classic",
    component: _8006bcf8,
    name: "civic-classic___en"
  }, {
    path: "/en/civic/dashboard",
    component: _11aab31b,
    name: "civic-dashboard___en"
  }, {
    path: "/en/civic/trial",
    component: _f16c5a40,
    name: "civic-trial___en"
  }, {
    path: "/en/gutenberg/free-audio-books",
    component: _5a6da03e,
    name: "gutenberg-free-audio-books___en"
  }, {
    path: "/en/nft/claim",
    component: _6fa96558,
    name: "nft-claim___en"
  }, {
    path: "/en/nft/gift",
    component: _063401ac,
    name: "nft-gift___en"
  }, {
    path: "/en/oauth/redirect",
    component: _26f4ef18,
    name: "oauth-redirect___en"
  }, {
    path: "/en/shopping-cart/book",
    component: _28ab58d2,
    name: "shopping-cart-book___en"
  }, {
    path: "/en/shopping-cart/wnft",
    component: _2d5ffc93,
    name: "shopping-cart-wnft___en"
  }, {
    path: "/en/store/articles",
    component: _43fa4eef,
    name: "store-articles___en"
  }, {
    path: "/en/store/books",
    component: _2ed60e6b,
    name: "store-books___en"
  }, {
    path: "/en/store/featured",
    component: _ba8bff40,
    name: "store-featured___en"
  }, {
    path: "/en/store/free",
    component: _455b7ec4,
    name: "store-free___en"
  }, {
    path: "/en/store/paid",
    component: _6f995cfe,
    name: "store-paid___en"
  }, {
    path: "/en/writing-nft/about",
    component: _16151b96,
    name: "writing-nft-about___en"
  }, {
    path: "/zh-Hant/about/nft-book",
    component: _000c1b60,
    name: "about-nft-book___zh-Hant"
  }, {
    path: "/zh-Hant/about/writing-nft",
    component: _d7e6fb96,
    name: "about-writing-nft___zh-Hant"
  }, {
    path: "/zh-Hant/auth/redirect",
    component: _226569cf,
    name: "auth-redirect___zh-Hant"
  }, {
    path: "/zh-Hant/campaign/qrcode",
    component: _268708d9,
    name: "campaign-qrcode___zh-Hant"
  }, {
    path: "/zh-Hant/campaign/writing-nft",
    component: _408e6c90,
    name: "campaign-writing-nft___zh-Hant"
  }, {
    path: "/zh-Hant/civic/classic",
    component: _8006bcf8,
    name: "civic-classic___zh-Hant"
  }, {
    path: "/zh-Hant/civic/dashboard",
    component: _11aab31b,
    name: "civic-dashboard___zh-Hant"
  }, {
    path: "/zh-Hant/civic/trial",
    component: _f16c5a40,
    name: "civic-trial___zh-Hant"
  }, {
    path: "/zh-Hant/gutenberg/free-audio-books",
    component: _5a6da03e,
    name: "gutenberg-free-audio-books___zh-Hant"
  }, {
    path: "/zh-Hant/nft/claim",
    component: _6fa96558,
    name: "nft-claim___zh-Hant"
  }, {
    path: "/zh-Hant/nft/gift",
    component: _063401ac,
    name: "nft-gift___zh-Hant"
  }, {
    path: "/zh-Hant/oauth/redirect",
    component: _26f4ef18,
    name: "oauth-redirect___zh-Hant"
  }, {
    path: "/zh-Hant/shopping-cart/book",
    component: _28ab58d2,
    name: "shopping-cart-book___zh-Hant"
  }, {
    path: "/zh-Hant/shopping-cart/wnft",
    component: _2d5ffc93,
    name: "shopping-cart-wnft___zh-Hant"
  }, {
    path: "/zh-Hant/store/articles",
    component: _43fa4eef,
    name: "store-articles___zh-Hant"
  }, {
    path: "/zh-Hant/store/books",
    component: _2ed60e6b,
    name: "store-books___zh-Hant"
  }, {
    path: "/zh-Hant/store/featured",
    component: _ba8bff40,
    name: "store-featured___zh-Hant"
  }, {
    path: "/zh-Hant/store/free",
    component: _455b7ec4,
    name: "store-free___zh-Hant"
  }, {
    path: "/zh-Hant/store/paid",
    component: _6f995cfe,
    name: "store-paid___zh-Hant"
  }, {
    path: "/zh-Hant/writing-nft/about",
    component: _16151b96,
    name: "writing-nft-about___zh-Hant"
  }, {
    path: "/en/nft/fiat/stripe",
    component: _191154df,
    name: "nft-fiat-stripe___en"
  }, {
    path: "/en/store/books/featured",
    component: _d10c4fb6,
    name: "store-books-featured___en"
  }, {
    path: "/en/store/books/free",
    component: _6f527a3a,
    name: "store-books-free___en"
  }, {
    path: "/en/store/books/paid",
    component: _5a9ddf43,
    name: "store-books-paid___en"
  }, {
    path: "/zh-Hant/nft/fiat/stripe",
    component: _191154df,
    name: "nft-fiat-stripe___zh-Hant"
  }, {
    path: "/zh-Hant/store/books/featured",
    component: _d10c4fb6,
    name: "store-books-featured___zh-Hant"
  }, {
    path: "/zh-Hant/store/books/free",
    component: _6f527a3a,
    name: "store-books-free___zh-Hant"
  }, {
    path: "/zh-Hant/store/books/paid",
    component: _5a9ddf43,
    name: "store-books-paid___zh-Hant"
  }, {
    path: "/en/nft/class/:classId",
    component: _681ec7a5,
    name: "nft-class-classId___en"
  }, {
    path: "/en/nft/collection/:collectionId",
    component: _7d712841,
    name: "nft-collection-collectionId___en"
  }, {
    path: "/en/nft/iscn/:iscnId?",
    component: _6b73cb28,
    name: "nft-iscn-iscnId___en"
  }, {
    path: "/zh-Hant/nft/class/:classId",
    component: _681ec7a5,
    name: "nft-class-classId___zh-Hant"
  }, {
    path: "/zh-Hant/nft/collection/:collectionId",
    component: _7d712841,
    name: "nft-collection-collectionId___zh-Hant"
  }, {
    path: "/zh-Hant/nft/iscn/:iscnId?",
    component: _6b73cb28,
    name: "nft-iscn-iscnId___zh-Hant"
  }, {
    path: "/en/nft/class/:classId?/share",
    component: _40c0b935,
    name: "nft-class-classId-share___en"
  }, {
    path: "/zh-Hant/nft/class/:classId?/share",
    component: _40c0b935,
    name: "nft-class-classId-share___zh-Hant"
  }, {
    path: "/en/nft/class/:classId?/:nftId",
    component: _a67fe096,
    name: "nft-class-classId-nftId___en",
    children: [{
      path: "message",
      component: _5d82b8a6,
      name: "nft-class-classId-nftId-message___en"
    }]
  }, {
    path: "/zh-Hant/nft/class/:classId?/:nftId",
    component: _a67fe096,
    name: "nft-class-classId-nftId___zh-Hant",
    children: [{
      path: "message",
      component: _5d82b8a6,
      name: "nft-class-classId-nftId-message___zh-Hant"
    }]
  }, {
    path: "/en/:id",
    component: _70418aa9,
    name: "id___en",
    children: [{
      path: "subscribe/:subscriptionId?",
      component: _f65139e8,
      name: "id-index-subscribe-subscriptionId___en"
    }, {
      path: "unsubscribe/:subscriptionId?",
      component: _f65139e8,
      name: "id-index-unsubscribe-subscriptionId___en"
    }]
  }, {
    path: "/zh-Hant/:id",
    component: _70418aa9,
    name: "id___zh-Hant",
    children: [{
      path: "subscribe/:subscriptionId?",
      component: _f65139e8,
      name: "id-index-subscribe-subscriptionId___zh-Hant"
    }, {
      path: "unsubscribe/:subscriptionId?",
      component: _f65139e8,
      name: "id-index-unsubscribe-subscriptionId___zh-Hant"
    }]
  }, {
    path: "/en/:id/civic",
    component: _8006bcf8,
    name: "id-civic___en"
  }, {
    path: "/zh-Hant/:id/civic",
    component: _8006bcf8,
    name: "id-civic___zh-Hant"
  }, {
    path: "/en/:id/civic/register",
    component: _415cca61,
    name: "id-civic-register___en"
  }, {
    path: "/zh-Hant/:id/civic/register",
    component: _415cca61,
    name: "id-civic-register___zh-Hant"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
