import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=665b2af4"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommunityCTA: require('/home/runner/work/liker-land/liker-land/src/components/CommunityCTA/CommunityCTA.vue').default,ButtonV2: require('/home/runner/work/liker-land/liker-land/src/components/ButtonV2.vue').default,Substack: require('/home/runner/work/liker-land/liker-land/src/components/Substack.vue').default,Dialog: require('/home/runner/work/liker-land/liker-land/src/components/Dialog.vue').default,Footer: require('/home/runner/work/liker-land/liker-land/src/components/Footer.vue').default})
