import { render, staticRenderFns } from "./AlertBanner.vue?vue&type=template&id=544dd3a0"
import script from "./AlertBanner.vue?vue&type=script&lang=js"
export * from "./AlertBanner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonV2: require('/home/runner/work/liker-land/liker-land/src/components/ButtonV2.vue').default,IconClose: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Close.vue').default})
