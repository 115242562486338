import { render, staticRenderFns } from "./Owning.vue?vue&type=template&id=5afb2334"
import script from "./Owning.vue?vue&type=script&lang=js"
export * from "./Owning.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconCreativeWork: require('/home/runner/work/liker-land/liker-land/src/components/Icon/CreativeWork.vue').default,Label: require('/home/runner/work/liker-land/liker-land/src/components/Label.vue').default})
