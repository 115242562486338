var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isOpen)?_c('Portal',{attrs:{"to":"dialog"}},[_c('div',{class:[
      'fixed',
      'inset-0',
      'overflow-x-hidden',
      'overflow-y-auto',
      _vm.zIndexClass,
    ]},[_c('div',{key:"scrollableContent",class:_vm.scrollableClasses},[_c('div',{key:"backdrop",class:['fixed', 'inset-0'],on:{"click":_vm.handleBackdropClick}}),_vm._v(" "),_c('div',{class:[
          'relative',
          'w-full',
          'max-w-[1920px]',
          'mx-auto',
          'p-[12px]',
          _vm.panelContainerClass,
        ]},[(_vm.hasCloseButton)?_c('ButtonV2',{class:[
            'absolute',
            'z-1',
            'bottom-full',
            'mb-[8px]',
            'ml-[16px] sm:ml-0',
            'w-[48px]',
            'h-[48px]',
            'text-like-green',
            'bg-shade-gray',
            'shadow-lg',
          ],attrs:{"preset":"plain","circle":true},on:{"click":_vm.close}},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.8383 3.74744C15.2289 3.35691 15.862 3.35691 16.2525 3.74744C16.6431 4.13796 16.6431 4.77113 16.2525 5.16165L11.4142 10L16.2525 14.8383C16.6431 15.2289 16.6431 15.862 16.2525 16.2526C15.862 16.6431 15.2289 16.6431 14.8383 16.2526L9.99998 11.4142L5.16164 16.2526C4.77111 16.6431 4.13795 16.6431 3.74742 16.2526C3.3569 15.862 3.3569 15.2289 3.74742 14.8383L8.58577 10L3.74742 5.16165C3.3569 4.77113 3.3569 4.13796 3.74742 3.74744C4.13795 3.35691 4.77111 3.35691 5.16164 3.74744L9.99998 8.58578L14.8383 3.74744Z","fill":"currentColor"}})])]):_vm._e(),_vm._v(" "),_vm._t("prepend"),_vm._v(" "),_c(_vm.panelComponent,{tag:"component",class:[
            'relative',
            'w-full',
            'mx-auto',
            'overflow-y-auto',
            'scrollbar-custom',
            _vm.panelClass,
          ],style:(_vm.panelStyle)},[(_vm.isBasic)?[(_vm.headerText)?_c('Label',{staticClass:"font-bold text-like-green mb-[24px]",attrs:{"tag":"header","preset":"h5","align":"left","text":_vm.headerText},scopedSlots:_vm._u([(_vm.$slots['header-prepend'])?{key:"prepend",fn:function(){return [_vm._t("header-prepend")]},proxy:true}:null],null,true)}):_vm._e(),_vm._v(" "),(_vm.text)?[_vm._v(_vm._s(_vm.text))]:_vm._t("default")]:_vm._t("default")],2),_vm._v(" "),(_vm.$slots.footer)?_c('div',{class:[
            'absolute',
            'bottom-0',
            'w-full',
            'rounded-b-[24px]',
            'overflow-hidden',
          ]},[_vm._t("footer")],2):_vm._e()],2)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }