import { render, staticRenderFns } from "./Sign.vue?vue&type=template&id=762b02b0"
import script from "./Sign.vue?vue&type=script&lang=js"
export * from "./Sign.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconAttention: require('/home/runner/work/liker-land/liker-land/src/components/Icon/Attention.vue').default,Label: require('/home/runner/work/liker-land/liker-land/src/components/Label.vue').default})
